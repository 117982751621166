<template>
  <div class="">
    <el-row :gutter="10" style="min-height: 60px;" v-loading="serviceConfig.isLoadData">
      <template v-if="serviceConfig.data && serviceConfig.data.length > 0">
        <template v-for="service in serviceConfig.data">
        <a target="_blank" :href="'/service/view/' + Service.serviceid" :key="Service.serviceid">
          <el-col :span="8" class="sctp-mar-b10">
            <div class="sctp-flex sctp-flex-column sctp-bg-white">
              <el-image
                style="width: 100%;height: 174px;"
                :src="Service.servicecovers[0]"
                fit="cover"
              ></el-image>
              <div class="sctp-pad-lr15 sctp-pad-tb10">
                <div class="sctp-flex sctp-flex-sb">
                  <div class="sctp-color-main"><span>¥</span>{{ Service.serviceprice }}</div>
                  <div>浏览数：{{ Service.hits || 0 }}</div>
                </div>
                <div class="sctp-ellipsis sctp-bold sctp-mar-t5">
                  {{ Service.servicename }}
                </div>
              </div>
              <div @click.prevent="" class="flex flex-sb pad-lr5">
                <div></div>
                <div>
                  <el-dropdown @command="handleCommand($event, Service)" size="mini">
                    <i class="el-icon-more"></i>
                    <el-dropdown-menu slot="dropdown">
                      <template v-for="item in dropdownOptions(Service)">
                        <el-dropdown-item :command="item">{{ item.label }}</el-dropdown-item>
                      </template>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </div>
            </div>
          </el-col>
        </a>
      </template>
      </template>
      <template v-else>
        <div class="empty-text">暂无数据</div>
      </template>
    </el-row>
    <div class="sctp-pad-tb10 sctp-flex sctp-flex-fe sctp-bg-white sctp-mar-t10">
      <el-pagination
        :current-page="serviceConfig.pageConfig.page"
        :page-size="serviceConfig.pageConfig.limit"
        @size-change="serviceConfig.pageConfig.handleSizeChange"
        @current-change="serviceConfig.pageConfig.handleCurrentChange"
        :page-sizes="serviceConfig.pageConfig.pageSizes"
        layout="prev, pager, next, total, sizes"
        :total="serviceConfig.pageConfig.total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import {collection} from "@/apis/index";
import area from "@/resources/js/area";

export default {
  name: 'favorite-service',
  data() {
    return {
      serviceConfig: {
        isLoadData: false,
        data: [],
        loadData: () => {
          let {pageConfig: {page, limit}} = this.serviceConfig;
          this.serviceConfig.isLoadData = true;
          collection.getService({
            userId: this.user.userId,
            page, limit,
          }).then(res => {
            let {retdata, count = 0} = res;
            this.serviceConfig.data = retdata
            this.serviceConfig.pageConfig.total = count;
          }).finally(() => {
            this.serviceConfig.isLoadData = false;
          });
        },
        pageConfig: {
          page: 1,
          limit: 12,
          total: 0,
          pageSizes: [1, 2, 5, 10].map(item => item * 12),
          handleSizeChange: (limit) => {
            this.serviceConfig.pageConfig.page = 1;
            this.serviceConfig.pageConfig.limit = limit;
            this.serviceConfig.loadData();
          },
          handleCurrentChange: (page) => {
            this.serviceConfig.pageConfig.page = page;
            this.serviceConfig.loadData();
          }
        },
      }
    }
  },
  methods: {
    handleCommand: (command, item) => {
      command.command(item);
    },
  },
  computed: {
    dropdownOptions() {
      return function (item) {
        let options = [];
        options = [
          {
            label: '取消收藏', command: () => {
              collection.addFavorite({
                userId: (this.user && this.user.userId) || null,
                targetId: item.serviceid,
                targetType: 5
              }).then(res => {
                const {retdata} = res;
                if (retdata) {
                  this.$message.success('收藏成功')
                } else {
                  this.$message.success('取消收藏成功')
                }
                this.serviceConfig.loadData();
              });
            }
          },
        ];
        return options;
      }
    },
    getCity() {
      return function (code) {
        return area.parseCity(code).name;
      }
    },
  },
  beforeMount() {
    this.serviceConfig.loadData();
  }
}
</script>

<style scoped>
</style>
